import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { motion, AnimatePresence } from "framer-motion";
import { AuthContext } from "../../context/AuthContext";

const Header = () => {
  const [showSubMenus, setShowSubMenus] = useState(false);
  const { logout } = useContext(AuthContext);

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <Title>Admin Section</Title>
        <Nav onMouseLeave={() => showSubMenus && setShowSubMenus(false)}>
          <Text>Howdy, admin</Text>
          <DownArrow
            onClick={() => setShowSubMenus(!showSubMenus)}
            width="23"
            height="15"
            viewBox="0 0 23 15"
            fill="none">
            <path
              d="M2 1L12.5 14L23 1"
              stroke="black"
              stroke-opacity="0.4"
              stroke-width="3"
              stroke-linejoin="round"
            />
          </DownArrow>
          <AnimatePresence>
            {showSubMenus && (
              <SubMenus
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                onClick={() => setShowSubMenus(false)}>
                <StyledLink to="/">Home</StyledLink>
                <StyledLink to="/users">Users</StyledLink>
                <SubMenu onClick={logout}>LogOut</SubMenu>
              </SubMenus>
            )}
          </AnimatePresence>
        </Nav>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  border-bottom: 1px solid #e4ebf2;
`;

const HeaderWrapper = styled.div`
  max-width: 122rem;
  margin: 0 auto;
  padding: 0 3rem;
  background-color: white;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 1.7rem;
  color: #333;
  padding: 1rem 0;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 1rem 0;
`;

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  text-align: right;
  margin-right: 1rem;
  color: #333;
  font-weight: 700;

  @media (max-width: 1082px) {
    font-size: 1.4rem;
  }
`;

const DownArrow = styled.svg`
  width: 1rem;
  cursor: pointer;
`;

const SubMenus = styled(motion.ul)`
  position: absolute;
  top: 100%;
  right: 0;
  list-style: none;
  z-index: 40;
  border: 0.5px solid #e4ebf2;
`;

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  font-weight: normal;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  text-align: center;
  cursor: pointer;
  color: #333;
  text-decoration: none;
  background-color: white;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #e4ebf2;
  }
`;

const SubMenu = styled.li`
  padding-left: 0;
  font-weight: normal;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  text-align: center;
  cursor: pointer;
  color: #333;
  background-color: white;
  text-decoration: none;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #e4ebf2;
  }
`;

export default Header;
