import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import { AuthContext } from "../../../../context/AuthContext";
import { AlertContext } from "../../../../context/AlertContext";
import { SERVER_URI } from "../../../../constants";
import moment from "moment";
import { Link } from "@reach/router";
import Checkbox from "./../../../../components/utility/Checkbox";

const ITEMS_PER_PAGE = 50;

const AllUsers = () => {
  const { auth } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const [users, setUsers] = useState(null);
  const [marked, setMarked] = useState([]);
  const [totalUsers, setTotalUsers] = useState(null);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setPage(1);
    setMarked([]);
  }, [searchQuery]);

  useEffect(() => {
    setMarked([]);
    axios
      .get(
        `${SERVER_URI}/users?name=${searchQuery}&limit=${ITEMS_PER_PAGE}&skip=${
          (page - 1) * ITEMS_PER_PAGE
        }`,
        { headers: { admintoken: auth.token } }
      )
      .then(({ data: { users, totalUsers } }) => {
        setUsers(users);
        setTotalUsers(totalUsers);
      });
  }, [page, searchQuery]);

  const handleBulkDelete = async () => {
    axios
      .post(
        `${SERVER_URI}/users/delete/bulk`,
        { emails: marked },
        { headers: { admintoken: auth.token } }
      )
      .then(async ({ data: { message } }) => {
        // RELOAD DATA
        addAlert({ message });
        axios
          .get(
            `${SERVER_URI}/users?name=${searchQuery}&limit=${ITEMS_PER_PAGE}&skip=${
              (page - 1) * ITEMS_PER_PAGE
            }`,
            { headers: { admintoken: auth.token } }
          )
          .then(({ data: { users, totalUsers } }) => {
            setUsers(users);
            setTotalUsers(totalUsers);
          });
      });
  };

  const handleDownloadUserList = () => {
    window.open(`${SERVER_URI}/users/download?admintoken=${auth.token}`);
  };

  return (
    <Container>
      <TableWrapper>
        <TableContent>
          <ActionsContainer>
            <Button color="#1bb6c1" onClick={handleDownloadUserList}>
              Download User List
            </Button>
            {marked.length > 0 && (
              <Button color="#e02900" onClick={handleBulkDelete}>
                Delete
              </Button>
            )}
          </ActionsContainer>
          <FormGroup>
            <Input
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </FormGroup>
          <TableHeader>
            <TableHeaderItem></TableHeaderItem>
            <TableHeaderItem>Name</TableHeaderItem>
            <TableHeaderItem>Email</TableHeaderItem>
            <TableHeaderItem>End Date</TableHeaderItem>
          </TableHeader>
          <TableBody>
            {users && users.length > 0 ? (
              users.map((user) => (
                <TableRowContainer key={user._id}>
                  <Checkbox
                    checked={marked.includes(user.email)}
                    onChange={(checked) => {
                      if (checked) {
                        setMarked([...marked, user.email]);
                      } else {
                        const newSetOfMarked = marked.filter(
                          (email) => email !== user.email
                        );
                        setMarked(newSetOfMarked);
                      }
                    }}
                  />
                  <TableRowItem>{user.name}</TableRowItem>
                  <TableRowItem>{user.email}</TableRowItem>
                  <TableRowItem>
                    {moment(new Date(user.subscriptionEndDate)).format(
                      "YYYY/MM/DD"
                    )}
                  </TableRowItem>
                  <StyledLink to={user._id}>Edit</StyledLink>
                </TableRowContainer>
              ))
            ) : (
              <TableRowContainer>
                <TableRowItem>No USERS</TableRowItem>
              </TableRowContainer>
            )}
          </TableBody>
        </TableContent>
      </TableWrapper>
      {totalUsers ? (
        <PagesContainer>
          {page !== 1 && (
            <>
              <Page onClick={() => setPage(page - 1)}>Previous</Page>
              <Page onClick={() => setPage(page - 1)}>{page - 1}</Page>
            </>
          )}

          <Page active={true}>{page}</Page>

          {page !== Math.ceil(totalUsers / ITEMS_PER_PAGE) && (
            <>
              <Page onClick={() => setPage(page + 1)}>{page + 1}</Page>
              <Page
                onClick={() => {
                  setPage(page + 1);
                }}>
                Next
              </Page>
            </>
          )}
        </PagesContainer>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  max-width: 120rem;
  background-color: white;
  margin: 2rem auto;
  margin-top: 0;
  padding: 3rem 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 3rem;
  overflow-x: auto;
  white-space: nowrap;
`;

const TableContent = styled.div`
  width: 70rem;
  margin: 0 auto;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: hidden;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 2fr 0.7fr 0.5fr;
  grid-gap: 1rem;
  padding: 0.2rem 3rem;
  background-color: #f0f5f8;
`;

const TableHeaderItem = styled.p`
  padding: 1rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  color: #6c7887;
`;

const TableBody = styled.div``;

const TableRowContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 2fr 0.7fr 0.5fr;
  grid-gap: 1rem;
  padding: 0 3rem;
  border-bottom: 0.2px solid #3b82c5e0;
`;

const TableRowItem = styled.p`
  padding: 10px;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
`;

const PagesContainer = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: center;
`;

const Page = styled.p`
  padding: 1rem 1.5rem;
  margin: 0 0.5rem;
  border-radius: 0.2rem;
  background-color: ${({ active }) => (active ? "#7c7e80" : "#f0f5f8")};
  color: ${({ active }) => (active ? "white" : "#6c7887")};

  font-size: 1.4rem;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  padding: 10px;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  font-style: italic;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Button = styled.button`
  font-size: 1.4rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: ${({ color }) => color};
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-left: 10px;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default AllUsers;
