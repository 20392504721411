import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";

const Home = () => {
  return (
    <Container>
      <Wrapper>
        <RouterTitle>Home</RouterTitle>
        <Header>
          <StyledLink
            to=""
            getProps={({ isCurrent }) => {
              return {
                style: {
                  color: isCurrent ? "#0e1011" : "#889db3",
                  borderBottomColor: isCurrent ? "#0e1011" : "0e1011",
                },
              };
            }}>
            Dashboard
          </StyledLink>
        </Header>
        <ContentWrapper></ContentWrapper>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  margin: 2rem 0;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  max-width: 120rem;
  margin: 0 auto;
  padding: 3rem;
`;

const RouterTitle = styled.h1`
  color: #0e1011;
  font-size: 3rem;
  margin-bottom: 2rem;
`;

const Header = styled.ul`
  display: flex;
  list-style: none;
  border-bottom: 0.5px solid #0e1011;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 2rem 1.5rem 1.5rem 1.5rem;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  transition: all 0.2s;
`;

const ContentWrapper = styled.div``;

export default Home;
