import React, { useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { SERVER_URI } from "./../../constants";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../../components/utility/Loading";
import LoginImage from "./LoginImage";
import { AlertContext } from "../../context/AlertContext";

const Login = () => {
  const [form, setForm] = useState({});
  const { login } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
     // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc0FkbWluIjp0cnVlLCJpYXQiOjE2MjYyNzg5NDd9.jvEJby4Jw1bpWFPRm0vu1yQP8h6rVxRtFHEOOBrta2c';
      const {
       
        data: { token },
      } = await axios.post(`${SERVER_URI}/auth/admin/login`, { ...form });
      login({ token });
    } catch (err) {
      addAlert({ message: "Provide Valid Credentials" });
    }
  };

  return (
    <Wrapper>
      <LeftSide>
        <Title>Welcome Back</Title>
        <LoginForm onSubmit={handleLogin}>
          <LoginTitle>
            Log In To Manage
            <br /> Vijay Wealth Advisor
          </LoginTitle>
          <FormGroup>
            <Label>Username</Label>
            <Input
              placeholder="Username"
              value={form.username}
              onChange={(e) => {
                setForm({ ...form, username: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              type="password"
              placeholder="Password"
              value={form.password}
              onChange={(e) => {
                setForm({ ...form, password: e.target.value });
              }}
            />
          </FormGroup>

          {loading ? (
            <Loading />
          ) : (
            <SubmitButton value="Login" type="submit" onClick={handleLogin} />
          )}
        </LoginForm>
      </LeftSide>
      <RightSide>
        <LoginImage />
      </RightSide>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 0.8fr 1fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const RightSide = styled.div`
  background-color: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;

  svg {
    width: 60%;
  }
`;

const LeftSide = styled.div`
  padding: 5rem;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: space-evenly;
`;

const Title = styled.h2`
  color: #05a0ab;
  font-size: 3rem;
  width: 34rem;
  font-weight: 700;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 30rem;
  }

  @media (max-width: 768px) {
    width: 26rem;
  }
`;

const LoginForm = styled.form`
  width: 40rem;
  padding: 3rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    padding: 2rem;
    width: 30rem;
  }
`;

const LoginTitle = styled.h2`
  margin-bottom: 2rem;
  color: #05a0ab;
  font-size: 1.8rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const SubmitButton = styled.input`
  font-size: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

const GoogleLoginButton = styled.button`
  font-size: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

const LoginFooter = styled.div`
  margin: 0 auto;
  width: 34rem;
  @media (max-width: 1000px) {
    width: 30rem;
  }
  @media (max-width: 768px) {
    width: 26rem;
  }
`;

const FooterText = styled.p`
  font-size: 1.2rem;
  color: grey;
  text-align: center;
`;

const HorizontalLine = styled.hr`
  border: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  padding: 0.8rem 0;
`;

export default Login;
