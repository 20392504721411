import React, { useContext } from "react";
import { Router } from "@reach/router";
import Navigation from "./components/Navigation";

import Login from "./pages/Login";
import Home from "./pages/Home";
import Users from "./pages/Users";
import { AuthContext } from "./context/AuthContext";

const RootDOM = () => {
  const { auth } = useContext(AuthContext);
  if (!auth) {
    return <Login />;
  }
  return (
    <div>
      <Navigation />
      <Router>
        <Users path="users/*" />
        <Home path="/" />
      </Router>
    </div>
  );
};

export default RootDOM;
