import React, { useState, useContext, useRef } from "react";
import styled from "styled-components";
import { AuthContext } from "../../../../context/AuthContext";
import Loading from "../../../../components/utility/Loading";
import axios from "axios";
import { SERVER_URI } from "../../../../constants";
import { AlertContext } from "../../../../context/AlertContext";
import { CSVReader } from "react-papaparse";

const BulkAdd = () => {
  const { auth } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();
  const buttonRef = useRef();

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    const allUsers = [];
    data.splice(0, 1);
    data.map(({ data: row }) => {
      row[0] &&
        allUsers.push({
          name: row[0],
          email: row[1],
          phoneNumber: row[2],
          telegramNumber: row[3],
          subscriptionDuration: row[4],
          password: row[5] || "password123",
        });
    });
    setUsers(allUsers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    try {
      setLoading(true);
      await axios.post(
        `${SERVER_URI}/users/bulk`,
        { users },
        {
          headers: { admintoken: auth.token },
        }
      );

      window.scrollTo(0, 0);
      return addAlert({ message: `${users.length} Users Addd` });
    } catch (err) {
      const { errors } = err.response.data;
      users && addAlert({ message: `${users.length} Users Added` });
      errors.forEach((eachError) => {
        eachError.forEach((err) => {
          addAlert({
            message: `${Object.keys(err)[0].toUpperCase()} : ${
              Object.values(err)[0]
            }`,
          });
        });
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Title>Add Users In Bulk</Title>
      <CSVReader ref={buttonRef} onFileLoad={handleOnFileLoad} noClick noDrag>
        {({ file }) => (
          <aside
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
            }}>
            <Button onClick={handleOpenDialog}>Browse file</Button>
            <Label>{file && file.name}</Label>
          </aside>
        )}
      </CSVReader>

      {users &&
        (loading ? (
          <Loading />
        ) : (
          <Button type="submit" onClick={handleSubmit}>
            Add Users - {users.length}
          </Button>
        ))}
    </Form>
  );
};

const Form = styled.form`
  width: 40rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    width: 30rem;
  }
  @media (max-width: 360px) {
    width: auto;
  }
`;

const Title = styled.h2`
  margin: 5rem 0 2rem 0;
  color: #1bb6c1;
  font-size: 2.2rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const Button = styled.button`
  font-size: 1.4rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

export default BulkAdd;
