import React, { useState, useContext } from "react";
import styled from "styled-components";
import { AuthContext } from "../../../../context/AuthContext";
import Loading from "../../../../components/utility/Loading";
import axios from "axios";
import { SERVER_URI } from "../../../../constants";
import validator from "validator";
import { AlertContext } from "../../../../context/AlertContext";

const passwordRegEx = /^[a-zA-Z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~]{8,}$/;

const AddUser = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    telegramNumber: "",
    subscriptionDuration: 1,
    password: "",
  });
  const { auth } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.isEmail(form.email)) {
      return addAlert({ message: "Enter Valid Email" });
    }
    if (String(form.phoneNumber).length < 10) {
      return addAlert({ message: "Enter Valid Telegram Number" });
    }
    if (String(form.telegramNumber).length < 10) {
      return addAlert({ message: "Enter Valid Phone Number" });
    }
    if (!passwordRegEx.test(form.password)) {
      return addAlert({
        message:
          "Password must be at least 8 characters, a special character, an uppercase and a lowercase.",
      });
    }

    if (loading) {
      return;
    }

    try {
      setLoading(true);
      await axios.post(`${SERVER_URI}/users`, form, {
        headers: { admintoken: auth.token },
      });
      setForm({
        name: "",
        email: "",
        phoneNumber: "",
        telegramNumber: "",
        subscriptionDuration: 1,
        password: "",
      });
      window.scrollTo(0, 0);
      return addAlert({ message: "User successfully Created" });
    } catch (err) {
      err &&
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.forEach((err) => {
          addAlert({
            message: `${Object.keys(err)[0].toUpperCase()} : ${
              Object.values(err)[0]
            }`,
          });
        });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Title>Add User</Title>
      <FormGroup>
        <Label>Name</Label>
        <Input
          placeholder="Name"
          value={form.name}
          onChange={(e) => {
            setForm({ ...form, name: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Email</Label>
        <Input
          placeholder="Email"
          value={form.email}
          onChange={(e) => {
            setForm({ ...form, email: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Telegram Number</Label>
        <Input
          placeholder="Telegram Number"
          value={form.telegramNumber}
          onChange={(e) => {
            setForm({ ...form, telegramNumber: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Phone Number</Label>
        <Input
          placeholder="Phone Number"
          value={form.phoneNumber}
          onChange={(e) => {
            setForm({ ...form, phoneNumber: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Subscription Duration</Label>
        <Input
          type="number"
          placeholder="Subscription Duration"
          value={form.subscriptionDuration}
          onChange={(e) => {
            if (parseInt(e.target.value) < 1) {
              return;
            }
            setForm({
              ...form,
              subscriptionDuration: parseInt(e.target.value),
            });
          }}
        />
      </FormGroup>

      <FormGroup>
        <Label>Password</Label>
        <Input
          type="password"
          placeholder="Password"
          value={form.password}
          onChange={(e) => {
            setForm({ ...form, password: e.target.value });
          }}
        />
      </FormGroup>

      {loading ? (
        <Loading />
      ) : (
        <SubmitButton value="Add User" type="submit" onClick={handleSubmit} />
      )}
    </Form>
  );
};

const Form = styled.form`
  width: 40rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    width: 30rem;
  }
  @media (max-width: 360px) {
    width: auto;
  }
`;

const Title = styled.h2`
  margin: 5rem 0 2rem 0;
  color: #1bb6c1;
  font-size: 2.2rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const SubmitButton = styled.input`
  font-size: 1.4rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

export default AddUser;
